<template>
  <el-dialog :title="data.status?'选择文章':'选择活动'" :visible.sync="openDialog" width="60%" :show-close="false"
    class="dialog-vertical" @close="cancel">
    <div class="head-filter">
      <el-select size="medium" v-if="data.status" v-model="filterForm.source_id" placeholder="选择来源"
        @change="handleFilter">
        <el-option v-for="item in AllSourceList" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-input size="medium" v-model="filterForm.keyword" placeholder="请输入名称" @change="handleFilter">
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
    </div>
    <div class="member-list">
      <el-table :data="SelectList" style="width: 100%" :height="250" ref="multipleTable"
        @selection-change="handleSelectionChange" @row-click="handleCurrentChange" v-loading="loading"
        v-loadmore="LoadMore">
        <!-- 多选 -->
        <el-table-column fixed type="selection" width="70" :selectable="selectable" :key="Math.random()">
        </el-table-column>
        <template v-for="(th, index) in thead[filterForm.status?'article':'activity']">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :key="index">
            <template slot-scope="scope">
              <span>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  postedList,
  AddList,
  DeleteList,
  AllSource,
} from "../api/brand-list";
import debounce from 'lodash/debounce'
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    selectData: {
      type: Object,
    },
    upload: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: {
        activity: [
          { label: "活动名称", prop: "activity_name", minWidth: 150 },
          { label: "活动时间", prop: "activity_time_text", minWidth: 160 },
        ],
        article: [
          { label: "标题", prop: "article_title", minWidth: 150 },
          { label: "来源", prop: "source_name", minWidth: 110 },
          { label: "发布时间", prop: "post_time", minWidth: 160 },
        ],
      },
      SelectList: [], // 活动/文章列表
      getable: true, // 是否还可以获取列表
      //筛选对象
      filterForm: {
        brand_id: 0,
        source_id: "",
        mark: 0,
        status: 0,
        keyword: "", //关键词
        page_size: 10, //每页多少条数据
        page: 1,
      },
      AllSourceList: [],
      selectArr: [], //勾选的数组id
      saveLoading: false,
    };
  },
  methods: {
    // 获取活动/文章列表(下拉刷新防抖动优化)
    getPostedList: debounce(function () {
      if (!this.getable) return;
      this.loading = true;
      const pageData = { ...this.filterForm };
      pageData.source_id = pageData.source_id === "" ? -1 : pageData.source_id;
      postedList(pageData)
        .then((res) => {
          const { data } = res;
          if (pageData.page === 1) {
            this.SelectList = data;
          } else {
            this.SelectList = [...this.SelectList, ...data];
          }
          if (data.length < 10) this.getable = false;
          this.filterForm.page++;
          this.loading = false;
        })
        .catch((err) => {});
    }, 200),
    // 获取来源列表
    getAllSourceList() {
      AllSource({ portal_user_id: 0 })
        .then((res) => {
          this.AllSourceList = res.data;
        })
        .catch((err) => {});
    },
    // 获取架构列表
    getParentToList() {
      parentToList({ crr_level: -1, is_show_all: 1 })
        .then((res) => {
          const { data } = res;
          data.map((item) => {
            item.children = [];
          });
          this.OrganizationList = data;
        })
        .catch((err) => {});
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      if (val.is_added) return;
      this.SelectList.forEach((element) => {
        if (val.id === element.id) {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(element);
          });
        }
      });
    },
    // 判断是否可选
    selectable(row, index) {
      if (this.filterForm.mark) {
        return true;
      } else {
        if (row.is_added) {
          return false;
        } else {
          return true;
        }
      }
    },
    // 筛选操作
    handleFilter() {
      this.getable = true;
      this.filterForm.page = 1;
      this.getPostedList();
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        selection.forEach((element) => {
          this.selectArr.push(element.id);
        });
      }
    },
    //触底刷新
    LoadMore() {
      this.getPostedList();
    },
    // 确定
    submit() {
      if (!this.selectArr.length) {
        this.$message.info("请选择数据项");
      } else {
        const data = {};
        data.brand_id = this.selectData.id;
        data.status = this.selectData.status;
        data.data_ids = this.selectArr;
        this.saveLoading = true;
        if (!this.filterForm.mark) {
          AddList(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.clearDialog();
              this.upload();
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          DeleteList(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.clearDialog();
              this.upload();
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        }
      }
    },
    // 清除弹窗数据
    clearDialog() {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
      this.filterForm.page = 1;
      this.getable = true;
      this.getPostedList();
      this.selectArr = [];
      this.saveLoading = false;
      this.openDialog = false;
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.getable = true;
        this.filterForm.brand_id = this.data.id;
        this.filterForm.status = this.data.status;
        this.filterForm.mark = this.data.mark;
        this.filterForm.keyword = "";
        this.filterForm.page = 1;
        this.getPostedList();
        this.getAllSourceList();
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    data() {
      return this.selectData;
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  width: 60%;
  display: flex;
  position: absolute;
  justify-content: flex-end;
  top: 20px;
  right: 20px;

  .el-select,
  .el-cascader,
  .el-input {
    width: 50%;
    margin-right: 10px;
  }
}

.member-list {
  ::v-deep.el-table__row {
    cursor: pointer;
  }
}
</style>
