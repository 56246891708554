import api from "@/base/utils/request";
import fetchFile from "@/base/utils/download";

// 获取品牌列表
export const brandList = data => {
  return api({
    url: "/admin/cyc/brand/index",
    method: "post",
    data
  });
};

// 更新排序
export const updateSort = data => {
  return api({
    url: "/admin/cyc/brand/updateSort",
    method: "post",
    data
  });
};

// 更新推荐
export const updateRecommend = data => {
  return api({
    url: "/admin/cyc/brand/updateRecommend",
    method: "post",
    data
  });
};

// 更新显示
export const updateShow = data => {
  return api({
    url: "/admin/cyc/brand/updateShow",
    method: "post",
    data
  });
};

// 删除移入回收站
export const softDelete = data => {
  return api({
    url: "/admin/cyc/brand/softDelete",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/cyc/brand/del",
    method: "post",
    data
  });
};

// 回收站还原数据
export const putBack = data => {
  return api({
    url: "/admin/cyc/brand/putBack",
    method: "post",
    data
  });
};

// 获取文章/活动列表
export const postedList = data => {
  return api({
    url: "/admin/cyc/brand/posted",
    method: "post",
    data
  });
};

// 获取已选择文章/活动列表
export const HaveChosenList = data => {
  return api({
    url: "/admin/cyc/brand/HaveChosen",
    method: "post",
    data
  });
};

// 添加文章/活动
export const AddList = data => {
  return api({
    url: "/admin/cyc/brand/add",
    method: "post",
    data
  });
};

// 删除文章/活动
export const DeleteList = data => {
  return api({
    url: "/admin/cyc/brand/deleteBrand",
    method: "post",
    data
  });
};

// 获取所有来源
export const AllSource = data => {
  return api({
    url: "/admin/portal/source/all",
    method: "post",
    data
  });
};

// 专题列表导出
export const documentBrandList = data => {
  return fetchFile({
    url: "/admin/cyc/brand/export",
    method: "post",
    data,
    download: true
  });
};